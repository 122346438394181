import styled, { device } from "@theme/utils";

export const SectionTwo = styled.div`
    margin-top: 30px;
    padding: 8px;
    ${device.mobileview} {
        & .mobile-view-reverse {
            flex-direction: column-reverse;
        }
    }
`;
export const Title = styled.h3``;
export const Description = styled.p`
    text-align: justify;
    text-justify: inter-word;
`;

export const DescriptionText = styled.ul`
    text-align: justify;
    text-justify: inter-word;
`;
